import { Box, Button, Typography, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import DOMPurify from 'dompurify'
import PropTypes from 'prop-types'
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  startTransition,
  useMemo,
} from 'react'

import { fetchMagicLink, submitFormData } from '../../services/formAPI'
import useFormCache from '../../utils/hooks/useFormCache'
import useFormCustomValidation from '../../utils/hooks/useFormCustomValidation'
import useFormValidation from '../../utils/hooks/useFormValidation'
import { sanitizeConfig } from '../../utils/sanitization'
import ProgressBar from '../Loading/ProgressBar'
import Spinner from '../Loading/Spinner'

import ErrorDisplay from './ErrorDisplay'
import FormRenderer from './FormRenderer'
import RecaptchaWrapper from './RecaptchaWrapper'

const FormContainer = styled(Box)(({ theme }) => ({
  width: '100%',
}))

const FormBody = styled('form')(({ theme }) => ({
  width: '100%',
}))

const FormSuccess = styled(Typography)(({ theme }) => ({
  color: 'rgb(9, 42, 72)',
  marginBottom: theme.spacing(2.5),
}))

const GetHelp = styled(Typography)(({ theme }) => ({
  color: 'red',
  margin: theme.spacing(2.5, 0),
  '& a': {
    color: 'rgb(9, 42, 72)',
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgb(9, 42, 72)',
  border: 'none',
  fontSize: '14px',
  borderRadius: '25px',
  padding: theme.spacing(1.25, 2.5),
  color: 'rgb(255, 255, 255)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  marginTop: theme.spacing(2.5),
  marginBottom: theme.spacing(5),
  '&:disabled': {
    backgroundColor: 'rgb(221, 229, 237)',
    color: 'rgb(9, 72, 42)',
    cursor: 'not-allowed',
  },
}))

const FormComponent = ({
  formId,
  customValidation,
  isModal,
  ctaText,
  dataLocation,
  formCSRF,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [recaptcha, setRecaptcha] = useState('')
  const [magicLinkSent, setMagicLinkSent] = useState(false)
  const [formValues, setFormValues] = useState({})
  const [thanksMessage, setThanksMessage] = useState('')

  const uniqueFormId = isModal ? `mFA${formId}` : `FA${formId}`

  const { formHTML, hiddenFields, loading: formLoading, error: formError } =
    useFormCache(formId, formCSRF)

  const {
    isFetchingRole,
    fetchUserRole,
    validateAccessRole,
    accessRole,
    error: validationError,
  } = useFormCustomValidation(customValidation)

  const formFields = []

  const {
    fieldErrors,
    validateField,
    validateAllFields,
    setFieldErrors,
    clearErrors,
    validationRules,
  } = useFormValidation(formHTML, formFields, formValues)

  const fieldRefs = useRef({})

  const safeValidationRules = useMemo(
    () => validationRules || {},
    [validationRules]
  )

  const stripHtml = useCallback((html) => {
    if (!html) return ''
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')
    return doc.body.textContent || ''
  }, [])

  const sendMagicLinkHandler = useCallback(async (email, name, tokenVal, data) => {
    try {
      return await fetchMagicLink(email, name, tokenVal, data)
    } catch (error) {
      if (error.name !== 'AbortError') {
        setErrorMessage('Failed to send magic link. Please try again.')
      }
      throw error
    }
  }, [])

  const handleFormSubmission = useCallback(
    async (email, role, name) => {
      try {
        const formElement = document.getElementById(uniqueFormId)
        if (!formElement) {
          throw new Error('Form not found')
        }
        const responseText = await submitFormData(formElement, recaptcha)
        startTransition(() => {
          setFormValues((prevValues) => ({
            ...prevValues,
            ...DOMPurify.sanitize(responseText, sanitizeConfig),
          }))
        })
        if (formId === 29 && role === 'Institutional') {
          await sendMagicLinkHandler(email, name, formCSRF, dataLocation)
        }
        setThanksMessage(stripHtml(responseText))
      } catch (error) {
        if (error.name !== 'AbortError') {
          setErrorMessage(
            error.message ||
              'There was an error submitting the form. Please try again.'
          )
        }
      } finally {
        setMagicLinkSent(true)
      }
    },
    [
      uniqueFormId,
      recaptcha,
      formId,
      sendMagicLinkHandler,
      stripHtml,
      formCSRF,
      dataLocation,
    ]
  )

  const submitForm = useCallback(
    async (email, role, name) => {
      try {
        await handleFormSubmission(email, role, name)
      } catch (error) {
        if (error.name !== 'AbortError') {
          setErrorMessage(
            error.message ||
              'There was an error submitting the form. Please try again.'
          )
        }
        setIsSubmitting(false)
      } finally {
        setFormSubmitted(true)
        setIsSubmitting(false)
        setErrorMessage('')
      }
    },
    [handleFormSubmission]
  )

  useEffect(() => {
    if (formHTML && hiddenFields && Object.keys(hiddenFields).length > 0) {
      startTransition(() => {
        setFormValues((prevValues) => ({
          ...prevValues,
          ...hiddenFields,
        }))
      })
    }
  }, [formHTML, hiddenFields])

  const handleInputChange = useCallback(
    (e) => {
      const { id, value, type, checked } = e.target
      const fieldValue = type === 'checkbox' || type === 'radio' ? checked : value
      setFormValues((prevValues) => ({
        ...prevValues,
        [id]: fieldValue,
      }))
      validateField(id, fieldValue)
    },
    [validateField]
  )

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }, [])

  const handleRecaptchaExpire = useCallback(() => {
    setRecaptcha('')
  }, [])

  const handleProgressBarComplete = useCallback(() => {}, [])

  const handleCustomSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      clearErrors()
      setErrorMessage('')
      const { hasError, newFieldErrors } = validateAllFields()
      if (hasError) {
        setFieldErrors(newFieldErrors)
        setErrorMessage('Please fix the errors in the form.')
        const firstErrorField = Object.keys(newFieldErrors)[0]
        if (firstErrorField && fieldRefs.current[firstErrorField]) {
          fieldRefs.current[firstErrorField].focus()
        }
        return
      }
      if (!recaptcha) {
        setErrorMessage('Please complete the CAPTCHA.')
        return
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const emailEntry = Object.entries(formValues).find(
        ([, value]) =>
          typeof value === 'string' && emailRegex.test(value.trim())
      )
      if (!emailEntry) {
        setErrorMessage('Please provide a valid email address.')
        return
      }
      const [, emailValue] = emailEntry
      const email = emailValue.trim()
      setIsSubmitting(true)
      setErrorMessage('')
      try {
        if (customValidation) {
          const userData = await fetchUserRole(email, formCSRF)
          if (!userData) {
            setIsSubmitting(false)
            return
          }
          const { name, role } = userData
          const { message, isValid } = validateAccessRole(role)
          if (isValid === false) {
            setErrorMessage(message)
            setIsSubmitting(false)
            return
          }
          await submitForm(email, role, name)
        } else {
          await submitForm(email, undefined, undefined)
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          setErrorMessage(
            error.message ||
              'There was an error submitting the form. Please try again.'
          )
        }
        setIsSubmitting(false)
      }
    },
    [
      clearErrors,
      customValidation,
      fetchUserRole,
      recaptcha,
      setFieldErrors,
      submitForm,
      validateAccessRole,
      validateAllFields,
      formValues,
    ]
  )

  return (
    <FormContainer>
      {formLoading && <Spinner />}
      {!formSubmitted && formHTML && (
        <FormBody
          id={uniqueFormId}
          onSubmit={handleCustomSubmit}
          onKeyDown={handleKeyDown}
          action="https://forms.harborcapital.com/api_v2/workflow/processor"
          noValidate
        >
          <FormRenderer
            formHTML={formHTML}
            formValues={formValues}
            handleInputChange={handleInputChange}
            fieldErrors={fieldErrors}
            fieldRefs={fieldRefs}
            validationRules={safeValidationRules}
          />
          {!formSubmitted && (
            <>
              <RecaptchaWrapper
                formId={uniqueFormId}
                gatsbyRecaptchaKey={process.env.GATSBY_RECAPTCHA_KEY  || ''}
                onRecaptchaChange={setRecaptcha}
                onRecaptchaExpire={handleRecaptchaExpire}
                formSubmitted={formSubmitted}
              />
              {errorMessage && (
                <ErrorDisplay errorMessage={errorMessage} fieldErrors={fieldErrors} />
              )}
              {errorMessage && (
                <GetHelp>
                  Looking for additional support?{' '}
                  <a href="mailto:WebTeam@harborcapital.com">Click here</a> to
                  get in touch.
                </GetHelp>
              )}
              <SubmitButton
                type="submit"
                disabled={!recaptcha || isSubmitting}
                variant="contained"
              >
                {isSubmitting ? (
                  <>
                    <CircularProgress size={24} color="inherit" />
                    &nbsp;Submitting...
                  </>
                ) : (
                  ctaText
                )}
              </SubmitButton>
            </>
          )}
        </FormBody>
      )}
      {thanksMessage && (
        <FormSuccess variant="body1">{thanksMessage}</FormSuccess>
      )}
      {(isSubmitting || formSubmitted) && (
        <ProgressBar isSubmitting={isSubmitting} onComplete={handleProgressBarComplete} />
      )}
    </FormContainer>
  )
}

FormComponent.propTypes = {
  formId: PropTypes.number.isRequired,
  customValidation: PropTypes.string,
  isModal: PropTypes.bool,
  ctaText: PropTypes.string,
  dataLocation: PropTypes.any,
  formCSRF: PropTypes.any,
}

export default FormComponent

import React, { createContext, useState, useContext, useCallback, useMemo } from 'react'

const NavigationHistoryContext = createContext()

export const NavigationHistoryProvider = ({ children }) => {
  const [history, setHistory] = useState([])

  const addToHistory = useCallback((location) => {
    setHistory((prevHistory) => [...prevHistory, location])
  }, [])

  const getPreviousLocation = useCallback(() => {
    if (history.length < 2) return null
    return history[history.length - 2]
  }, [history])

  const contextValue = useMemo(() => ({ addToHistory, getPreviousLocation }), [addToHistory, getPreviousLocation])

  return (
    <NavigationHistoryContext.Provider value={contextValue}>
      {children}
    </NavigationHistoryContext.Provider>
  )
}

export const useNavigationHistory = () => useContext(NavigationHistoryContext)

import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

const TokenContext = createContext({
  isAuthVerified: false,
  isCITVerified: false,
  csrfToken: null,
  fetchCsrfToken: async () => null,
  verifyCITMagicLinkToken: async () => null,
  verifyCITToken: async () => false,
  verifyMagicLinkToken: async () => false,
  verifyAuthToken: async () => false,
  logout: async () => {},
  citLogout: async () => {},
  loading: false,
  error: null,
})

export const useToken = () => useContext(TokenContext)

export const TokenProvider = ({ children }) => {
  const [isCITVerified, setIsCITVerified] = useState(false)
  const [isAuthVerified, setIsAuthVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [csrfToken, setCsrfToken] = useState('')

  const fetchCsrfToken = useCallback(async () => {
    if (csrfToken) return csrfToken
    try {
      const response = await fetch('/api/csrf-token', {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      })
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`)
      const data = await response.json()
      setCsrfToken(data.token)
      return data.token
    } catch (err) {
      setError('Failed to fetch CSRF token.')
      return null
    }
  }, [csrfToken])

  const makePostRequest = useCallback(
    async (url, body) => {
      setLoading(true)
      setError(null)
      try {
        let postToken = csrfToken
        if (!postToken) {
          postToken = await fetchCsrfToken()
        }
        if (!postToken) {
          throw new Error('CSRF token is missing. Make sure it is fetched before making requests.')
        }

        const controller = new AbortController()
        const timeoutId = setTimeout(() => controller.abort(), 30000)

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-csrf-token': postToken,
          },
          body: JSON.stringify(body),
          credentials: 'include',
          signal: controller.signal,
        })

        clearTimeout(timeoutId)

        if (!response.ok) {
          const errorData = await response.json()
          throw new Error(errorData.error || 'Request failed.')
        }

        const newCsrfToken = response.headers.get('x-csrf-token')
        if (newCsrfToken && newCsrfToken !== postToken) {
          setCsrfToken(newCsrfToken)
        }
        return await response.json()
      } catch (err) {
        if (err.name === 'AbortError') {
          setError('Request timed out. Please try again.')
        } else {
          setError(err.message || 'An error occurred during the request.')
        }
        throw err
      } finally {
        setLoading(false)
      }
    },
    [csrfToken, fetchCsrfToken]
  )

  const verifyCITMagicLinkToken = useCallback(
    async (token) => {
      try {
        const data = await makePostRequest('/api/cit-verify-magic-token', {
          token,
          tokenType: 'cit-magic-link',
        })
        if (data && data.firstUse && data.email) {
          return { firstUse: data.firstUse, email: data.email, message: data.message }
        }
        if (data && data.email) {
          return { email: data.email, message: data.message }
        }
        return null
      } catch (err) {
        return null
      }
    },
    [makePostRequest]
  )

  const verifyMagicLinkToken = useCallback(
    async (token) => {
      try {
        const data = await makePostRequest('/api/auth-verify-magic-token', {
          token,
          tokenType: 'magic-link',
        })
        if (data && data.firstUse) {
          setIsAuthVerified(true)
          return { firstUse: data.firstUse, message: data.message }
        }
        return false
      } catch (err) {
        return false
      }
    },
    [makePostRequest]
  )

  const verifyAuthToken = useCallback(async () => {
    try {
      const data = await makePostRequest('/api/auth-verify-token', {})
      if (data) {
        setIsAuthVerified(true)
        return true
      }
      setIsAuthVerified(false)
      return false
    } catch {
      setIsAuthVerified(false)
      return false
    }
  }, [makePostRequest])

  const verifyCITToken = useCallback(async () => {
    try {
      const data = await makePostRequest('/api/cit-verify-token', {})
      if (data && data.email) {
        setIsCITVerified(true)
        return data.email
      }
      setIsCITVerified(false)
      throw new Error('Invalid or expired CIT token.')
    } catch (err) {
      setIsCITVerified(false)
      throw err
    }
  }, [makePostRequest])

  const logout = useCallback(async (redirectUrl = '/') => {
    try {
      const data = await makePostRequest('/api/auth-logout', {})
      if (data) {
        setIsAuthVerified(false)
        setIsCITVerified(false)
        if (typeof window !== 'undefined') {
          window.location.href = redirectUrl
        }
      }
    } catch {
      setError('Logout failed.')
    }
  }, [makePostRequest])

  const citLogout = useCallback(async () => {
    try {
      const data = await makePostRequest('/api/cit-logout', {})
      if (data) {
        setIsCITVerified(false)
        if (typeof window !== 'undefined') {
          window.location.href = '/citconnect'
        }
      }
    } catch {
      setError('Logout failed.')
    }
  }, [makePostRequest])

  const contextValue = useMemo(
    () => ({
      isAuthVerified,
      isCITVerified,
      csrfToken,
      fetchCsrfToken,
      verifyAuthToken,
      verifyCITToken,
      verifyCITMagicLinkToken,
      verifyMagicLinkToken,
      logout,
      citLogout,
      loading,
      error,
    }),
    [
      isAuthVerified,
      isCITVerified,
      csrfToken,
      fetchCsrfToken,
      verifyAuthToken,
      verifyCITToken,
      verifyCITMagicLinkToken,
      verifyMagicLinkToken,
      logout,
      citLogout,
      loading,
      error,
    ]
  )

  return (
    <TokenContext.Provider value={contextValue}>
      {children}
    </TokenContext.Provider>
  )
}

import DOMPurify from 'dompurify'
import { sanitizeConfig, removeFARecaptchaHook } from '../utils/sanitization'

const postJSON = async (url, data, csrfToken, timeout = 60000) => {
  const controller = new AbortController()
  const timer = setTimeout(() => controller.abort(), timeout)

  const headers = {
    'Content-Type': 'application/json',
  }

  if (csrfToken) {
    headers['x-csrf-token'] = csrfToken
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers,
      credentials: 'same-origin',
      body: JSON.stringify(data),
      signal: controller.signal,
    })

    if (!response.ok) {
      const errorText = await response.text()
      throw new Error(`Error: ${response.status} ${response.statusText} - ${errorText}`)
    }

    const contentType = response.headers.get('Content-Type')
    if (contentType && contentType.includes('application/json')) {
      return await response.json()
    }

    return await response.text()
  } catch (error) {
    if (error.name === 'AbortError') {
      throw new Error('Request timed out')
    }
    throw error
  } finally {
    clearTimeout(timer)
  }
}

const getRequest = async (url, options = {}, timeout = 10000) => {
  const controller = new AbortController()
  const timer = setTimeout(() => controller.abort(), timeout)

  try {
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      ...options,
      signal: controller.signal,
    })

    if (!response.ok) {
      const errorText = await response.text()
      throw new Error(`Error: ${response.status} ${response.statusText} - ${errorText}`)
    }

    const contentType = response.headers.get('Content-Type')
    if (contentType && contentType.includes('application/json')) {
      return await response.json()
    }

    return await response.text()
  } catch (error) {
    if (error.name === 'AbortError') {
      throw new Error('Request timed out')
    }
    throw error
  } finally {
    clearTimeout(timer)
  }
}

export const fetchUserRoleAPI = async (email, token) => {
  if (!token) {
    throw new Error('Function token is required.')
  }
  if (!email) {
    throw new Error('Email is required.')
  }

  return await postJSON('/api/salesforce-api', { email }, token)
}

export const fetchMagicLink = async (email, name, token, dataLocation) => {
  if (!token) {
    throw new Error('Function token is required.')
  }
  if (!email) {
    throw new Error('Email is required.')
  }

  return await postJSON('/api/auth-magic-link', { email, name, dataLocation }, token)
}

export const fetchCitLink = async (email, token) => {
  if (!token) {
    throw new Error('CSRF token is required.')
  }
  if (!email) {
    throw new Error('Email is required.')
  }

  return await postJSON('/api/cit-magic-link', { email }, token)
}

export const submitFormData = async (formElement, recaptcha) => {
  if (!formElement || !(formElement instanceof HTMLFormElement)) {
    throw new Error('Valid form element is required.')
  }
  if (!recaptcha) {
    throw new Error('reCAPTCHA token is required.')
  }

  const formData = new FormData(formElement)
  formData.append('g-recaptcha-response', recaptcha)

  const response = await fetch(formElement.action, {
    method: 'POST',
    body: formData,
    credentials: 'same-origin',
  })

  if (!response.ok) {
    const errorText = await response.text()
    throw new Error(`Error submitting form: ${response.status} ${response.statusText} - ${errorText}`)
  }

  return await response.text()
}

export const fetchFormHTML = async (formId, token) => {
  if (!token) {
    throw new Error('Function token is required.')
  }
  if (!formId) {
    throw new Error('Form ID is required.')
  }

  DOMPurify.addHook('uponSanitizeElement', removeFARecaptchaHook)

  try {
    const url = `/api/fetch-fa-form?formId=${encodeURIComponent(formId)}`
    const response = await getRequest(url, {
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': token,
      },
    })
    const sanitizedHTML = DOMPurify.sanitize(response, sanitizeConfig)
    return sanitizedHTML
  } catch (error) {
    console.error('Failed to fetch and sanitize form HTML:', error)
    throw error
  } finally {
    DOMPurify.removeHook('uponSanitizeElement', removeFARecaptchaHook)
  }
}

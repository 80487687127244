import { useEffect } from 'react'
import { useLocation } from '@gatsbyjs/reach-router'
import { useNavigationHistory } from './context/NavigationHistoryContext'

const HistoryTracker = () => {
  const location = useLocation()
  const { addToHistory } = useNavigationHistory()

  useEffect(() => {
    addToHistory(location)
  }, [location, addToHistory])

  return null
}

export default HistoryTracker

import React from 'react'
import PropTypes from 'prop-types'
import SideNavigationContextProvider from './utils/context/sideNavigationContext'
import { ContactUsCollapseContexProvider } from './utils/context/ContactUsCollapseContext'
import GatedContentHandler from './utils/GatedContentHandler'
import { NavigationHistoryProvider } from './utils/context/NavigationHistoryContext'
import HistoryTracker from './utils/historyTracker'

const WrapPageElement = ({ element, props = {} }) => {
  const location = props.location || {}

  return (
    <GatedContentHandler location={location}>
      <NavigationHistoryProvider>
        <HistoryTracker />
        <SideNavigationContextProvider>
          <ContactUsCollapseContexProvider>
              {element}
          </ContactUsCollapseContexProvider>
        </SideNavigationContextProvider>
      </NavigationHistoryProvider> 
    </GatedContentHandler>
  )
}

WrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
  props: PropTypes.any,
}

export default WrapPageElement

import PropTypes from 'prop-types'
import React, { createContext, useCallback, useReducer, useMemo, useContext } from 'react'

const initialSearchState = {
  results: [],
  status: 'idle',
  searchTerms: '',
  error: null,
}

const SearchContext = createContext({
  ...initialSearchState,
  search: () => {},
})

const searchReducer = (state, action) => {
  switch (action.type) {
    case 'search::start':
      return { ...state, status: 'loading', searchTerms: action.payload, error: null }
    case 'search::finish':
      return { ...state, status: 'idle', results: action.payload, error: null }
    case 'search::error':
      return { ...state, status: 'idle', error: action.payload }
    default:
      return state
  }
}

const SearchProvider = ({ searchFn, children }) => {
  const [state, dispatch] = useReducer(searchReducer, initialSearchState)

  const search = useCallback(async (searchTerms) => {
    dispatch({ type: 'search::start', payload: searchTerms })
    try {
      const results = await searchFn(searchTerms)
      dispatch({ type: 'search::finish', payload: results })
    } catch (error) {
      dispatch({ type: 'search::error', payload: error.message || 'Unknown error' })
    }
  }, [searchFn])

  const contextValue = useMemo(() => ({ ...state, search }), [state, search])

  return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>
}

SearchProvider.propTypes = {
  searchFn: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

const DiscoveryEngineProvider = ({ children }) => {
  const searchFn = useCallback(async (searchTerms) => {
    if (!searchTerms) return []

    const requestBody = {
      query: searchTerms,
    }

    const cloudRunUrl =
      'https://us-central1-harborcapital-se-1627069964559.cloudfunctions.net/vertex'

    const response = await fetch(cloudRunUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })

    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'Vertex AI Search error')
    }

    const searchData = await response.json()
    const results = searchData.results || []
    return results.map(result => ({ id: result.id, document: result.document }))
  }, [])

  return <SearchProvider searchFn={searchFn}>{children}</SearchProvider>
}

DiscoveryEngineProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useSearch = () => {
  const context = useContext(SearchContext)
  if (context === undefined) throw new Error('useSearch must be used within a SearchProvider')
  return context
}

const SearchContextExport = {
  Context: SearchContext,
  DiscoveryEngineProvider,
  useSearch,
}

export default SearchContextExport

import React, { createContext } from 'react'

const PrivatePagesContext = createContext([])

export const PrivatePagesProvider = ({ children, privatePages }) => {
  return (
    <PrivatePagesContext.Provider value={privatePages}>
      {children}
    </PrivatePagesContext.Provider>
  )
}

export default PrivatePagesContext

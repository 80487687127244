import React, { useEffect } from 'react'
import { ContentstackGatsby } from 'gatsby-source-contentstack/live-preview'
import ContentstackLivePreview from '@contentstack/live-preview-utils'

export const CSStack = typeof window !== 'undefined'
  ? new ContentstackGatsby({
      api_key: process.env.CONTENTSTACK_API_KEY,
      delivery_token: process.env.CONTENTSTACK_DELIVERY_TOKEN,
      environment: process.env.CONTENTSTACK_ENVIRONMENT,
      live_preview: {
        enable: true,
        preview_token: process.env.CONTENTSTACK_PREVIEW_TOKEN,
        preview_host: 'rest-preview.contentstack.com',
      },
    })
  : null

const ContentstackPreviewProvider = ({ children }) => {
  useEffect(() => {
    if (process.env.CONTENTSTACK_ENVIRONMENT === 'test' && CSStack) {
      ContentstackLivePreview.init({
        enable: true,
        ssr: true,
        mode: 'builder',
        stackSdk: CSStack.stackSdk,
        stackDetails: {
          apiKey: process.env.CONTENTSTACK_API_KEY,
          environment: process.env.CONTENTSTACK_ENVIRONMENT,
        },
        editButton: {
          enable: false,               
          includeByQueryParameter: false,
        },
        clientUrlParams: {
          host: 'app.contentstack.com',
        },
      })
    }
  }, [])

  return <>{children}</>
}

export default ContentstackPreviewProvider
